exports.components = {
  "component---src-sites-type-ak-light-3-design-templates-add-domain-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-add-domain-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-category-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/Category.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-category-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-contact-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-contact-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-home-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-home-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-not-found-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-not-found-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-search-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-search-tsx" */),
  "component---src-sites-type-ak-light-3-design-templates-terms-tsx": () => import("./../../../src/sites/typeAkLight/3-design/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-3-design-templates-terms-tsx" */)
}

